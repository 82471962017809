.link-button{
    display: inline-flex;
    background-color: #6c568e;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
    /* width: 200px; */
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    margin: 10px 0px;
}
.link-button:hover {
    background-color: #a1ca66;
    color:black;
  }

  .link-button-fb{
    display: inline-flex;
    background-color: #1877f2;
    color: #fff;
    padding: 10px;
    border-radius: 20px;
    /* width: 200px; */
    text-align: center;
    font-size: 20px;
    text-decoration: none;
    margin: 10px 0px;
}