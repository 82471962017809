.sticky-bottom{
    margin-top: auto;
    background-color:#a1ca66;
}
.purple{
    color:#6c568e;
}
.header-banner{
    background-color:#6c568e;
    margin-bottom: 24px;
    position: sticky;
    top:0px;
    z-index: 2;
}
.inner-margin{
    margin:6px;
}
.high-important{
    font-weight: bold;
    color: red;
}
.title-underline{    
    font-weight: 600;
    text-decoration: underline;
}
.no-puce{
    list-style:none;
}
.ul-margin li{
    margin-bottom:12px;
}
.bigger-size{
    font-size: 20px;
}
.link-icon{
    cursor: pointer;
}
/***********part loading*************/
.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: transparent;
    z-index: 1;
}
.loading-panel-overlay{
    z-index: 10;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: lightgray;
    opacity: 0.6;
}

.spinner {
    position: absolute;
    top: 20%;
    left: 30%;
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #6c568e transparent #6c568e transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.spinner-modal {
    top:45%!important;
    left: 40%!important;
}