.msg-info{
    color:gray;
}
.msg-info-header{
    font-size:20px;
}
.bt-subscription{
    width: 100%;
}
.header-grid{
    font-weight: 500;
}
.spinner-modal-container{
    height: 100px;
}
