.footer{
    background-color: #a1ca66;
    margin: 6px;
}

.footer a{
    font-size:18px;
    color:dimgray;
}
.footer h5{
    color:white;
    font-size: 20px;
}
.footer a:hover{
    color:#e36c68;
}
.footer ul {
    list-style-type: none;
}
.footer svg {
    margin-right: 6px;
    color:dimgray;
}
.footer .about a{
    color:#e36c68!important;
}