h1{
    font-size: large; 
    width: 80%;   
}
.banner{
    width: 100%;
}
.main-logo{
    width: 100%;
    text-align: center;
}
.main-description{
    color:#256FFF;
    padding:24px 0px;
}
  .nav-logo{
    height: 60px;
    margin-right: 12px;
  }
  .main-description p{
    color:black;
    font-size:16px;
  }