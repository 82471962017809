.nav{
    color:white;
}
.content{
    overflow:auto;
}
a:hover {
    color: #a1ca66;
  }
/***********/
  .navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0.5rem 0rem;
    background-color: #6c568e;
    color: black;
    /*box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);*/
  }
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  .navigation-menu {
    margin-left: auto;
  }
  .brand-name {
    text-decoration: none;
    color: #fff;
    font-size: 1.3rem;
    margin-left: 1rem;
  }
  .navigation-menu {
    margin-left: auto;
  }
  .navigation-menu ul {
    display: flex;
    padding: 0;
    margin:0px;
  }
  .navigation-menu li {
    /*removes default disc bullet for li tags and applies margin to left & right side*/
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    /*increases the surface area of the anchor tag to span more than just the anchor text*/
    text-decoration: none;
    display: block;
    width: 100%;
    color:white;
    font-size:1.1rem;
  }
  .navigation-menu li a.active {
    color: #a1ca66;
    font-weight: 600;
  }
  .navigation-menu li a:hover {
    color:#a1ca66;
  }

  /*****tablet*****/
  .hamburger {
    /*removes default border on button element*/
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    /*positions the icon to the right and center aligns it vertically*/
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: #a1ca66;
  }

  @media screen and (max-width: 768px) {
    .hamburger {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /*navigation menu is positioned to start 60px from the top of the document (which is directly below the navbar)*/
      position: absolute;
      top: 60px;
      left: 0;
      /*stacks the li tags vertically */
      flex-direction: column;
      /*makes menu span full height and width*/
      width: 100%;
      height: calc(100vh - 77px);
      background-color: white;
      border-top: 1px solid black;
      z-index: 2;
    }
    .navigation-menu li {
      /*centers link text and strips off margin*/
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: black;
      /*increases the surface area of the anchor tag to span the full width of the menu*/
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color: #a1ca66;
      color:#fff!important;
    }
    .navigation-menu li a:hover {
        color:#fff!important;
      }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu ul {
      /* previous styles */
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    .navigation-menu.expanded ul {
      display: block;
    }
  }

  .li-connection{
    border-left: 1px solid white;
    padding-left: 12px;
  }