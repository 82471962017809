.card img{
    width: 100%;
    object-fit: fill;
}
.card-overlay{
    opacity: 0.6;
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
}
.card .head{
    position: absolute;
    top:50%;
    text-align: center;
}
.head-card{
    color:#3c4858;
    font-family: "CircularStd", sans-serif;
    font-weight:400;
    margin-top: 1rem!important;
}
.desc-card{
    color:#8492a6 !important;
}
.place-card{

}
.read-more{
    color:#f18a08 !important;
}